<template>
  <v-layout wrap align-center class="px-1">
    <template v-for="button in buttons">
      <v-divider
        class="mx-2"
        v-if="button.separator && !$vuetify.breakpoint.smAndDown"
        :key="button.tooltip"
        vertical
      ></v-divider>
      <div
        class="break"
        v-else-if="button.separator && $vuetify.breakpoint.smAndDown"
        :key="button.tooltip"
      ></div>
      <v-layout shrink v-else class="ma-1" :key="button.tooltip">
        <slot name="button" v-bind:button="button"></slot>
      </v-layout>
    </template>
    <slot name="append"></slot>
  </v-layout>
</template>

<script>
export default {
  props: {
    buttons: {},
  },
};
</script>

<style scoped>
.break {
  height: 1px !important;
  width: 100% !important;
}
</style>